import React from 'react';
import InfoItem from './InfoItem';
import DataCV from '../../data/resume.json';

const Info = () => {
    return (
        <div className="col-xs-12 col-sm-4">
            <div id="info_list_1" className="info-list-w-icon">
                <InfoItem iconClass="linecons linecons-phone" caption={DataCV.basics.phone} description="" />
                <InfoItem iconClass="linecons linecons-location" caption={DataCV.basics.location.city} description="" />
                <InfoItem iconClass="linecons linecons-mail" caption={DataCV.basics.email} description="" />
            </div>
        </div>
    );
};

export default Info;
