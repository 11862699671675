import React from 'react';
import MyPhoto from '../assets/img/main_photo.jpg';
import DataCV from '../data/resume.json';
import { NavLink } from 'react-router-dom';

const Aboutme = () => {
    return (
        <>
        <div className="row">
            <div className=" col-xs-12 col-sm-12">
                <div className="home-content">
                    <div className="row flex-v-align">                    
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <div className="home-photo">
                            <div className="hp-inner" style={{backgroundImage: "url(" + MyPhoto + ")"}}>
                            </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-7 col-lg-7">
                            <div className="home-text hp-left">
                                <div className="owl-carousel text-rotation">  
                                    {DataCV.basics.label.map((item, index) => (
                                        <div key={index} className="item">
                                        <h4>{item}</h4>
                                        </div>

                                    ))}                                 
                                </div>
                                <h1>{DataCV.basics.firstname} {DataCV.basics.name}</h1>
                                <p>{DataCV.basics.summary}</p>                                
                                <div className="home-buttons">
                                    <NavLink end to="/resume" className="btn btn-primary">
                                    <span>Mon CV</span>
                                    </NavLink>
                                    <NavLink end to="/contact" className="btn btn-secondary">
                                    <span>Contact</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </>
    );
};

export default Aboutme;