import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
            <div className="site-nav mobile-menu-hide">
              <ul className="leven-classic-menu site-main-menu">
                
                <li className="menu-item">
                    <NavLink end to="/" activeclassname="current-menu-item">
                        <span>Accueil</span>
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink end to="/resume" activeclassname="current-menu-item">
                        <span>CV</span>
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink end to="/contact" activeclassname="current-menu-item">
                        <span>Contact</span>
                    </NavLink>
                </li>
                <li className="menu-item">
                    <NavLink end to="/projets" activeclassname="current-menu-item">
                        <span>Projets</span>
                    </NavLink>
                </li>
              </ul>
            </div>
    );
};

export default Navigation;