import React from 'react';
import ProjetBloc from '../components/projet/ProjetBloc';

const Projets = () => {
    return (
        <>
        <div className="page-title">
            <h1>Projets</h1>
            <div className="page-subtitle">
                <h4>Domotique, Dev, ...</h4>
            </div>
        </div>
        <div id="content" className="page-content site-content single-post" role="main">
            <ProjetBloc />
        </div> 
    </>
    );
};

export default Projets;