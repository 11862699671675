import React from 'react';
import DataCV from '../../data/resume.json';
import { NavLink } from 'react-router-dom';
const LogoName = () => {
    return (
        <div className="text-logo">
            <NavLink end to="/">
                <div className="logo-symbol">JD</div>
                <div className="logo-text">{DataCV.basics.firstname} <span>{DataCV.basics.name}</span></div>
            </NavLink>
        </div>
    );
};

export default LogoName;