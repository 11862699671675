import React, {useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const FormContact = () => {
    const notify = () => toast("Désolé, une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer plus tard.");

    const form = useRef();
       
    const sendEmail = (e) => {

        if (!e.isDefaultPrevented()){
            emailjs.sendForm('service_9d3fazu', 'template_9lso1tq', form.current, '6uToz5UX1AjZ92Ox4')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                toast.success("Votre message a bien été envoyé, je vous répondrai dans les plus brefs délais.");
            }, (error) => {
                notify()
            }); 
        }
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
    };
    return (
        <div className="col-xs-12 col-sm-8">
            <div className="block-title">
                <h2>Vous souhaitez me contacter ?</h2>
            </div>
            <ToastContainer
                        position="bottom-right"
                        theme="dark"
                        />
            <form id="contact_form" ref={form} className="contact-form" onSubmit={sendEmail}>
                <div className="messages"></div>

                <div className="controls two-columns">
                    <div className="fields clearfix">
                        <div className="left-column">
                            <div className="form-group form-group-with-icon">
                                <input id="form_name" type="text" name="name" className="form-control" placeholder="Nom complet" required="required" data-error="Votre nom est requis." />
                                <div className="form-control-border"></div>
                                <div className="help-block with-errors"></div>
                            </div>

                            <div className="form-group form-group-with-icon">
                                <input id="form_email" type="email" name="email" className="form-control" placeholder="Adresse Email" required="required" data-error="Un email valide est requis" />
                                <div className="form-control-border"></div>
                                <div className="help-block with-errors"></div>
                            </div>

                            <div className="form-group form-group-with-icon">
                                <input id="form_subject" type="text" name="subject" className="form-control" placeholder="Objet" required="required" data-error="L'objet est requis." />
                                <div className="form-control-border"></div>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className="right-column">
                            <div className="form-group form-group-with-icon">
                                <textarea id="form_message" name="message" className="form-control" placeholder="Message" rows="7" required="required" data-error="Merci de mettre un message."></textarea>
                                <div className="form-control-border"></div>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="button btn-send disabled" value="Envoyer le  message"/>
                </div>
            </form>
        </div>
    );
};

export default FormContact;
