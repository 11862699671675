import React from 'react';
import LogoName from './header/LogoName';
import Navigation from './header/Navigation';

const Header = () => {
    return (
        <header id="site_header" className="header">
          <div className="header-content clearfix"> 
            <LogoName/>
            <Navigation/>
            <div className="menu-toggle mobile-visible">
              <i className="fa fa-bars"></i>
            </div>
          </div>
        </header>
    );
};

export default Header;