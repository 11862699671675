import React from 'react';
import Aboutme from '../components/Aboutme';

const Home = () => {
    return (
        <div>
           <Aboutme />
        </div>        
    );
};

export default Home;