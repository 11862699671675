import React from 'react';
import Certificate from './Certificate';
import DataCV from '../../data/resume.json';

const CertificateBloc = () => {
    return (
        <>
        <div className="row">
            <div className=" col-xs-12 col-sm-12 ">
                <div className="p-20"></div>
                <div className="block-title">
                <h2>Diplômes</h2>
                </div>
            </div>
        </div>
        <div className="row">
        {DataCV.certificates.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)).map((item, index) => (
                        <Certificate  key={index} CertName={item.name} Obtentiondate={item.date} CertEtablissement={item.issuer} LogoUrl={item.logo} schoolUrl={item.url}/>
                    ))}
        </div>
        
        </>
    );
};

export default CertificateBloc;