import React from 'react';
import Map from '../components/Contact/Map';
import Info from '../components/Contact/Info';
import FormContact from '../components/Contact/FormContact';

const Contact = () => {
    return (
        <>
            <div className="page-title">
                <h1>Contact</h1>
                <div className="page-subtitle">
                    <h4> Comment prendre contact</h4>
                </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
                 <Map />
                <div className="row">
                    <Info />
                    <FormContact />
                </div>
            </div>
        </>
    );
};

export default Contact;