import React from 'react';
import PropTypes from 'prop-types';

const FunFact = ({faIcon, Label, HowMany, OtherText}) => {
    return (
        <div className="col-xs-12 col-sm-4">
            <div className="lm-info-block gray-default">
                <i className={faIcon} />
                <h4 style={{whiteSpace:'pre-line'}}>{Label}</h4>
                <span className="lm-info-block-value">{HowMany}</span>
                <span className="lm-info-block-text">{OtherText}</span>
            </div>
        </div>
    );
};

FunFact.propTypes = {
    faIcon:PropTypes.string, 
    Label:PropTypes.string, 
    HowMany:PropTypes.string,
    OtherText:PropTypes.string   
};

export default FunFact;