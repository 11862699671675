import React from 'react';

const TryHackMeBadgeProfile = () => {
  return (
    <div>
        <a href={`https://tryhackme.com/r/p/krapocho`} target="_blank" rel="noopener noreferrer">
        <img 
          src="https://tryhackme-badges.s3.amazonaws.com/krapocho.png" 
          alt="Profile TryHackMe" 
        />
      </a>
    </div>

  );
};

export default TryHackMeBadgeProfile;